import React from "react"
import {Number, Text, Email, RadioLine, Birthday, Select, Password} from './Inputs'

export default class Forms extends React.Component {

    handleChange(e) {
        const target = e.target;
        this.props.changeForm({name: target.name, value: target.value});
    };

    handleChangeRadio(data) {
        this.props.changeForm({name: data.key, value: data.value});
        this.props.changeBlur(data.key);
    }

    handleBlur(e) {
        this.props.changeBlur(e.target.name);
    }

    renderForm() {

        let renderData = [];
        const{inputs,submitButton, models} = this.props;

        if (typeof (inputs) !== "undefined") {

            for (const [key, value] of Object.entries(inputs)) {

                let current = null;

                if (value.type === "email") {

                    current = <Email
                        value={models[value.name]}
                        name={value.name}
                        placeholder={value.placeholder}
                        onChange={this.handleChange.bind(this)}
                        onBlur={this.handleBlur.bind(this)}
                        key={"form-" + key}
                    />

                } else if (value.type === "text") {

                    current = <Text
                        value={models[value.name]}
                        name={value.name}
                        placeholder={value.placeholder}
                        onChange={this.handleChange.bind(this)}
                        onBlur={this.handleBlur.bind(this)}
                        key={"form-" + key}
                    />

                } else if (value.type === "number") {

                    current = <Number
                        value={models[value.name]}
                        name={value.name}
                        placeholder={value.placeholder}
                        onChange={this.handleChange.bind(this)}
                        onBlur={this.handleBlur.bind(this)}
                        key={"form-" + key}
                    />

                }else if (value.type === "password") {

                    current = <Password
                        value={models[value.name]}
                        name={value.name}
                        placeholder={value.placeholder}
                        onChange={this.handleChange.bind(this)}
                        key={"form-" + key}
                    />

                } else if (value.type === "select") {

                    current = <Select
                        value={models[value.name]}
                        name={value.name}
                        options={value.options}
                        onChange={this.handleChange.bind(this)}
                        onBlur={this.handleBlur.bind(this)}
                        key={"form-" + key}
                    />

                }else if (value.type === "radio") {

                    current = <RadioLine
                        fields={value.inputs}
                        name={value.name}
                        label={value.label}
                        models={models[value.name]}
                        handleChangeRadio={this.handleChangeRadio.bind(this)}
                        key={"form-" + key}
                    />

                }else if (value.type === "birthday") {
                        current = <Birthday
                            value={models[value.name]}
                            name={value.name}
                            onChange={this.handleChange.bind(this)}
                            onBlur={this.handleBlur.bind(this)}
                            key={"form-" + key}
                        />
                }

                if (!!current) {
                    renderData = [...renderData, current];
                }
            }
        }

        if (renderData.length > 0 && submitButton) {
            renderData = [...renderData, <button className="btn btn-success" key={"button-form"}>Valider</button>];
        }

        return renderData;
    }

    submitForm() {
        this.props.submitForm(true);
    }

    render() {
        const{submitButton,submitForm} = this.props;

        return (
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    if (typeof (submitButton) !== "undefined" && submitButton) {
                        submitForm()
                    }
                }}
            >
                {this.renderForm()}
            </form>
        )
    }
}
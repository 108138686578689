import React from 'react';
import {Link} from "react-router-dom";
import telephone_site from '../../assets/telephone_site.png'
import ecran_1 from '../../assets/ecrans_01.jpg'
import ecran_2 from '../../assets/ecrans_02.jpg'
import cc_logo from '../../assets/LOGO_C_Cies.jpg'
import bio_planete from '../../assets/logo_bioplanete.jpg'
import etamine from '../../assets/etamine_logo.jpg'
import pain_des_fleurs from '../../assets/Logo_LPDF_2016.jpg'
import android from '../../assets/android.png'
import ios from '../../assets/app_store.png'
import ReactGA from "react-ga";

function Home() {
    ReactGA.initialize('UA-24145657-10');
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
        [<div className="w-100" key={"home-0"}>
            <div className="w-100 red-part">
                <div className="text hd-home">
                    <p className="title">DES BONS PLANS DISPONIBLES EN MAGASINS BIO</p>
                    <p className="paragraph hidden-xs">
                        Biopromo est une application smartphone gratuite qui vous permet de profiter de bons plans
                        et de promotions sur des produits bio vendus en grandes surfaces et en magasins
                        spécialisés.<br/><br/>
                        Pour cela, il suffit d'acheter les produits en promotion dans votre magasin habituel, de
                        prendre en photo votre ticket de caisse grâce à l'application et c'est tout ! Vous recevez
                        ensuite la réduction en euros directement sur votre compte bancaire, par virement(~7jours)
                        ou Paypal (~30 jours). Vous pouvez également la reverser à l'association de votre choix.
                    </p>
                    <div id="preinscription">
                        <p className="msg_preinsription">Inscrivez-vous </p>
                        <Link to="/register" className="btn btn-default inscription_submit button-register">S'inscrire</Link>
                    </div>
                </div>
                <div className="w-100 green-part">
                    <p className="mobile_title sd-home"> DES BONS PLANS DISPONIBLE <br/>EN MAGASINS BIO</p>
                    <img alt="phone biopromo" src={telephone_site} className="diagonal_phone pull-right hd-home"/>
                    <h3 className="lancement  hd-home">1 €<br/><span className="s_lancement">Remboursé</span></h3>
                </div>
                <img alt="phone biopromo" src={telephone_site} className="diagonal_phone pull-right sd-home"/>
                <h3 className="lancement  sd-home">1 €<br/><span className="s_lancement">Remboursé</span></h3>
            </div>
        </div>,
            <div className="col-md-12" key={"home-1"}>
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="center-title text-center"> Qu'est ce que <span id="bio">bio</span>promo ?</h2>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-4 offset-1">
                                <img alt="ecran_1" className="ecran-1" src={ecran_1}/>
                            </div>
                            <div className="col-4">
                                <img alt="ecran_2" className="ecran-2" src={ecran_2}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <p className="center_text">
                            C'est simple, vous allez devenir fan de Biopromo! Pourquoi ? Parce que chaque semaine, des
                            centaines d'euros d'économies sont proposées sur des produits bio que vous consommez
                            régulièrement.<br/><br/>
                            <span className="fa fa-certificate fa-red" aria-hidden="true"/> Des dizaines d'offres à
                            découvrir<br/>
                            <span className="fa fa-certificate fa-red" aria-hidden="true"/> Des économies sur des
                            produits bio du quotidien<br/>
                            <span className="fa fa-certificate fa-red" aria-hidden="true"/> Des promos disponibles
                            partout<br/>
                            <span className="fa fa-certificate fa-red" aria-hidden="true"/> Des offres exclusives<br/>
                            <span className="fa fa-certificate fa-red" aria-hidden="true"/> Jusqu'à 100% remboursé<br/>
                        </p>
                    </div>
                </div>
            </div>,
            <div className="col-md-12 pb-5" key={"home-2"}>
                <div className={"row"}>
                    <div className="col-md-12 partenaires">
                        <img src={cc_logo} alt={""}/>
                        <img src={bio_planete} alt={""}/>
                        <img src={etamine} alt={""}/>
                        <img src={pain_des_fleurs} alt={""}/>
                    </div>
                </div>
            </div>,
            <div className={"pt-5"}  key={"home-3"}>
                <div className="col-md-12">
                    <div className="col-md-12">
                        <h2 className="center-title text-center app-title">Téléchargez l'application :</h2>
                    </div>
                    <div className={"col-md-12 app-imgs"}>
                        <div className={"row"}>
                            <div className={"col-md-6 text-right"}>
                                <a href={"https://play.google.com/store/apps/details?id=com.myapphone.biopromo"}>
                                    <img src={android}  alt={""} />
                                </a>
                            </div>
                            <div className={"col-md-6 text-left"}>
                                <a href={"https://itunes.apple.com/fr/app/biopromo/id1296272727?mt=8"}>
                                    <img src={ios}  alt={""} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ]
    );
}

export default Home;

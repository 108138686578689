import React from 'react'
import {PageTitle} from "../../global/Others";
import {withRouter} from 'react-router-dom'
import Helpers from "../../Helpers";
import Card from "./Remboursement/Card";
import CardTicketForm from "./Remboursement/CardTicketForm";
import ReactGA from "react-ga";

class Account extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            refunds: [],
            id_refund: '',
            showFileForm: false
        }
    }

    componentWillMount() {

        ReactGA.initialize('UA-24145657-10');
        ReactGA.pageview(window.location.pathname + window.location.search);

        const url = "https://api.biopromo.fr/v1/refund";
        Helpers.request(url, "GET")
            .then((response) => {
                if (response.status === 0) {
                    if (typeof (response.refunds) !== "undefined") {
                        this.setState({refunds: response.refunds});
                    }
                }
            });
    }

    loadDeals() {
        const url = "https://api.biopromo.fr/v1/refund";
        Helpers.request(url, "GET")
            .then((response) => {
                if (response.status === 0) {
                    if (typeof (response.refunds) !== "undefined") {
                        this.setState({refunds: response.refunds});
                    }
                }
            });
    }

    showInputForm(id_refund) {
        this.setState({showFileForm: true, id_refund: id_refund})
    }

    closeInputForm(sended) {
        this.setState({showFileForm: false, id_refund: ''});
        if (sended) {
            this.loadDeals();
        }
    }

    render() {
        let form = null;
        if (this.state.showFileForm) {
            form = <CardTicketForm key={"remboursement-part-2"} closeInputForm={this.closeInputForm.bind(this)}
                                   id_refund={this.state.id_refund}/>;
        }
        let cards = [];
        let total = 0;
        for (const [key, value] of Object.entries(this.state.refunds)) {
            cards = [...cards, <Card value={value} key={"refund-card-" + key} reloadDeals={this.loadDeals.bind(this)}
                                     showInputForm={this.showInputForm.bind(this)}/>];
            if (typeof (value.dealAmount) !== "undefined" && value.status === "OK") {
                total += value.dealAmount;
            }
        }
        total = parseFloat(total) / 100;

        return (
            [
                <div className="container pt-5" key={"remboursement-part-1"}>
                    <PageTitle title={"Mes remboursements"}/>
                    <div className="col-md-12">
                        <div className={"col-md-12"}>
                            <h5 className={"text-danger"}>Total: {total}€</h5>
                        </div>
                        <div className="col-md-12">
                            <div className={"row"}>
                                {cards}
                            </div>
                        </div>
                    </div>
                </div>,
                form
            ]
        )
    }

}

export default withRouter(Account)
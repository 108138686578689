import React from 'react'
import ReactGA from "react-ga";

function CentreAide() {

    ReactGA.initialize('UA-24145657-10');
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (<div className="container pt-5">
        <div className="col-md-12">
            <div className="row">
                <div className="col-md-12">
                    <h1 className="back-title">Centre d'aide</h1>
                </div>
                <div className="col-md-12">
                    <div className="container accod">
                        <div className="panel-group" id="accordion">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <a data-toggle="collapse" data-parent="#accordion"
                                           href="#collapse1">FONCTIONNEMENT</a>
                                    </h4>
                                </div>
                                <div id="collapse1" className="panel-collapse collapse in">
                                    <div className="panel-group" id="infos">
                                        <div className="panel-body">
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <h4 className="panel-title">
                                                        <a data-toggle="collapse" data-parent="#infos" href="#appli">Comment
                                                            obtenir l'application ?</a>
                                                    </h4>
                                                </div>
                                                <div id="appli" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                        <p>Attention : L’application est disponible sur Smartphones
                                                            Android, Tablettes Android, iPhone et iPad.</p>
                                                        <p>Pour télécharger l’application : </p>
                                                        <ul>
                                                            <li>
                                                                Rendez-vous sur
                                                                <a href="https://www.biopromo.fr"
                                                                   target="_blank">Biopromo.fr</a>
                                                            </li>
                                                            <li>App Store et Play Store : tapez « Biopromo »</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-body">
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <h4 className="panel-title">
                                                        <a data-toggle="collapse" data-parent="#infos" href="#compte">Comment
                                                            créer un compte ?</a>
                                                    </h4>
                                                </div>
                                                <div id="compte" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                        <p>
                                                            <u>Application : </u>
                                                        </p>
                                                        <p>Cliquez sur « Inscription »</p>
                                                        <p>Renseignez le formulaire d’inscription. Il est important que
                                                            vos informations personnelles soient complètes (nom/prénom,
                                                            adresse) et exactes. Dans le cas contraire, cela pourrait
                                                            poser un problème lors de l’envoi des cadeaux.</p>
                                                        <br />
                                                            <p>
                                                                <u>Site Internet :</u>
                                                            </p>
                                                            <p>
                                                                <a href="https://biopromo.fr/register">Biopromo.fr/register</a>
                                                            </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-body">
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <h4 className="panel-title">
                                                        <a data-toggle="collapse" data-parent="#infos" href="#secu">Mes
                                                            données sont-elles en sécurité ?</a>
                                                    </h4>
                                                </div>
                                                <div id="secu" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                        <p> Nous mettons tout en œuvre pour la sécurité de vos données.
                                                            Notre infrastructure technique et logicielle est développée
                                                            et implémentée en appliquant tous les standards de sécurité
                                                            respectés par les plus importants sites mondiaux. </p>
                                                        <p> Toutes les données sensibles (telles que votre mot de passe
                                                            et vos informations personnelles) sont envoyées sur nos
                                                            serveurs via SSL (le standard de l'industrie pour crypter
                                                            les communications) et stockées de façon cryptée dans nos
                                                            bases de données. Toutefois, aucun site n’est à l’abri d’une
                                                            tentative de piratage. </p>
                                                        <p> Par ailleurs, nous respectons votre vie privée et ne
                                                            communiquons en aucun cas vos informations personnelles, ni
                                                            ne vendons notre fichier d'abonnés. Le fichier comportant
                                                            les données à caractère personnel des utilisateurs du
                                                            service Biopromo.fr a fait l’objet d’une déclaration auprès
                                                            de la Commission Nationale de l’Informatique et des Libertés
                                                            (CNIL) sous le numéro 2106329. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-body">
                                            <div className="panel panel-default">
                                                <div className="panel-heading">
                                                    <h4 className="panel-title">
                                                        <a data-toggle="collapse" data-parent="#infos" href="#data">A
                                                            quoi servent les informations renseignées ?</a>
                                                    </h4>
                                                </div>
                                                <div id="data" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                        <p> Les données collectées, compilées avec celles de tous autres
                                                            utilisateurs, servent à créer des statistiques concernant
                                                            l'application Biopromo en interne. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h4 className="panel-title">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse2">MON COMPTE</a>
                                </h4>
                            </div>
                            <div id="collapse2" className="panel-collapse collapse">
                                <div className="panel-group" id="compte">
                                    <div className="panel-body">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#compte" href="#mail">Comment
                                                        modifier mon adresse email ?</a>
                                                </h4>
                                            </div>
                                            <div id="mail" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Voici les étapes à suivre :</p>
                                                    <ul>
                                                        <li>Cliquez sur votre nom d’utilisateur, en haut à droite.</li>
                                                        <li>Une fois sur la page "Mon Profil", cliquez sur l’onglet
                                                            "Modifier Mon Profil"
                                                        </li>
                                                        <li> Entrez votre nouvelle adresse email et cliquez sur
                                                            "Enregistrer" pour confirmer votre changement d'adresse
                                                            email
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-body">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#compte" href="#password">Comment
                                                        modifier mon mot de passe ?</a>
                                                </h4>
                                            </div>
                                            <div id="password" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Voici les étapes à suivre :</p>
                                                    <ul>
                                                        <li>Cliquez sur votre nom d’utilisateur, en haut à droite</li>
                                                        <li>Une fois sur la page "Mon Profil", cliquez sur l’onglet
                                                            "Modifier Mon Profil"
                                                        </li>
                                                        <li>Entrez votre ancien mot de passe, puis 2 fois votre nouveau
                                                            mot de passe et cliquez sur "Enregistrer" pour confirmer
                                                            votre changement de mot de passe
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-body">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#compte"
                                                       href="#passwordForgot">Mot de passe oublié, que dois-je faire
                                                        ?</a>
                                                </h4>
                                            </div>
                                            <div id="passwordForgot" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Voici les étapes à suivre :</p>
                                                    <ul>
                                                        <li>Sur la page de connexion ou d’identification, cliquez sur
                                                            "J’ai perdu mon mot de passe"
                                                        </li>
                                                        <li>Indiquez votre email et cliquez sur « Envoyer l’email de
                                                            réinitialisation de mot de passe » afin de recevoir un lien
                                                            par email, vous permettant de réinitialiser votre mot de
                                                            passe.
                                                        </li>
                                                    </ul>
                                                    <p>Que faire si je ne reçois pas d’email de réinitialisation de mot
                                                        de passe ?</p>
                                                    <ul>
                                                        <li>Vérifiez que l’email ne se trouve pas dans vos spams</li>
                                                        <li>Envoyez-nous un email à contact@biopromo.fr en indiquant
                                                            votre email de connexion et la non réception de l’email de
                                                            réinitialisation de mot de passe, nous reviendrons vers vous
                                                            très rapidement afin de vous permettre de vous connecter.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-body">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#compte"
                                                       href="#deleteCompte">Comment supprimer mon compte ?</a>
                                                </h4>
                                            </div>
                                            <div id="deleteCompte" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>Si vous souhaitez supprimer votre compte, envoyez-nous un email à
                                                        contact@biopromo.fr en indiquant votre email de connexion et
                                                        votre souhait d’être désinscrit. Nous supprimerons votre compte
                                                        sous 5 jours ouvrés. </p>
                                                    <p>Attention : tous les points cumulés et non transformés seront
                                                        également supprimés et ne pourront faire l’objet d’une
                                                        réclamation. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h4 className="panel-title">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse3">AUTRE</a>
                                </h4>
                            </div>
                            <div id="collapse3" className="panel-collapse collapse">
                                <div className="panel-group" id="other">
                                    <div className="panel-body">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">
                                                    <a data-toggle="collapse" data-parent="#other" href="#noResponse">Comment
                                                        modifier mon adresse email ?</a>
                                                </h4>
                                            </div>
                                            <div id="noResponse" className="panel-collapse collapse">
                                                <div className="panel-body">
                                                    <p>
                                                        Envoyez-nous un email à
                                                        <a href="mailto:contact@biopromo.fr"
                                                           target="_blank">contact@biopromo.fr </a>
                                                    </p>
                                                    <p> Nous traiterons votre demande dans les meilleurs délais. </p>
                                                    <p>Vous pouvez également appeler au 01.30.15.69.61 (ligne directe)
                                                        ou 01.30.15.02.03 (standard), du lundi au vendredi, de 9h30 à
                                                        13h et de 14h à 18h.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)
}

export default CentreAide
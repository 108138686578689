import $ from "jquery"

export default class Helpers {

    static async request(url, type, data) {
        let headers = {};
        if (typeof (window.jwt) !== "undefined") {
            headers["authorization"] = 'Bearer ' + window.jwt;
        }
        if (url !== "https://api.biopromo.fr/v1/user") {
            $('#call-loader').removeClass('d-none');
        }
        if (Object.keys(headers).length > 0) {

            const callBack = await $.ajax({
                url: url,
                headers: headers,
                type: type,
                dataType: 'json',
                crossDomain: true,
                data: typeof (data) !== "undefined" ? JSON.stringify(data) : ""
            }).catch(
                (err) => {
                    if (err.status === 401) {
                        localStorage.removeItem("session");
                        if (typeof(window.jwt !== "undefined")) {
                            delete window.jwt;
                        }
                    }
                    window.location.href = "/";
                }
            );

            $('#call-loader').addClass('d-none');

            return callBack;

        } else {
            const callBack = await $.ajax({
                url: url,
                type: type,
                dataType: 'json',
                crossDomain: true,
                data: typeof (data) !== "undefined" ? JSON.stringify(data) : "",
            }).catch(
                (err) => {

                }
            );

            $('#call-loader').addClass('d-none');

            return callBack;
        }
    }

}
import React from "react"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner, faCheck} from '@fortawesome/free-solid-svg-icons'

export const Email = (props) => {

    return (
        <div className={"form-group form-group-element " + props.name}>
            <input type="email"
                   className="form-control"
                   {...props}
            />
            <div className="loader-form d-none" title={"sauvegarde en cours"}>
                <FontAwesomeIcon icon={faSpinner} spin/>
            </div>
            <div className="loader-finish-form d-none" title={"sauvegarde effectué"}>
                <FontAwesomeIcon icon={faCheck}/>
            </div>
        </div>
    )
};

export const Password = (props) => {

    return (
        <div className={"form-group form-group-element " + props.name}>
            <input type="password"
                   className="form-control"
                   {...props}
            />
            <div className="loader-form d-none" title={"sauvegarde en cours"}>
                <FontAwesomeIcon icon={faSpinner} spin/>
            </div>
            <div className="loader-finish-form d-none" title={"sauvegarde effectué"}>
                <FontAwesomeIcon icon={faCheck}/>
            </div>
        </div>
    )
};

export const Text = (props) => {

    return (
        <div className={"form-group form-group-element " + props.name}>
            <input type="text"
                   className="form-control"
                   {...props}
            />
            <div className="loader-form d-none" title={"sauvegarde en cours"}>
                <FontAwesomeIcon icon={faSpinner} spin/>
            </div>
            <div className="loader-finish-form d-none" title={"sauvegarde effectué"}>
                <FontAwesomeIcon icon={faCheck}/>
            </div>
        </div>
    )
};

export const Number = (props) => {

    return (
        <div className={"form-group form-group-element " + props.name}>
            <input type="number"
                   className="form-control"
                   {...props}
            />
            <div className="loader-form d-none" title={"sauvegarde en cours"}>
                <FontAwesomeIcon icon={faSpinner} spin/>
            </div>
            <div className="loader-finish-form d-none" title={"sauvegarde effectué"}>
                <FontAwesomeIcon icon={faCheck}/>
            </div>
        </div>
    )
};

export const Select = (props) => {
    let options = [];
    for (const [key, value] of Object.entries(props.options)) {
        options = [...options,
            <option key={"options-account-selection-" + key} value={value.value}>{value.label}</option>];
    }
    return (
        <div className={"form-group form-group-element " + props.name}>
            <select className="form-control" {...props}>
                {options}
            </select>
            <div className="loader-form d-none" title={"sauvegarde en cours"}>
                <FontAwesomeIcon icon={faSpinner} spin/>
            </div>
            <div className="loader-finish-form d-none" title={"sauvegarde effectué"}>
                <FontAwesomeIcon icon={faCheck}/>
            </div>
        </div>
    );
};

export class RadioColumns extends React.Component {

    handleChange(e) {
        this.props.handleChangeRadio({key: e.target.name, value: e.target.value});
    }

    render() {
        const {fields, name, models} = this.props;
        let radio = [];
        for (const [key, value] of Object.entries(fields)) {
            radio = [...radio,
                <div className="custom-control custom-radio" key={name + "-" + key}>
                    <input
                        type="radio"
                        className="custom-control-input"
                        value={value.value}
                        name={name}
                        defaultChecked={models === value.value}
                        id={name + "-" + key}
                        onClick={this.handleChange.bind(this)}
                    />
                    <label className="custom-control-label" htmlFor={name + "-" + key}>{value.label}</label>
                </div>
            ]
        }

        return (
            <div className={"form-group form-group-element " + name}>
                {radio}
            </div>
        )
    }
}

export class RadioLine extends React.Component {

    handleChange(e) {
        this.props.handleChangeRadio({key: e.target.name, value: e.target.value});
    }

    render() {
        const {fields, name, models} = this.props;
        let radio = [];
        for (const [key, value] of Object.entries(fields)) {
            radio = [...radio,
                <div className="form-check form-check-inline" key={name + "-" + key}>
                    <input
                        type="radio"
                        className="form-check-input"
                        value={value.value}
                        name={name}
                        defaultChecked={models === value.value}
                        id={name + "-" + key}
                        onClick={this.handleChange.bind(this)}
                    />
                    <label className="form-check-label" htmlFor={name + "-" + key}>{value.label}</label>
                </div>
            ]
        }

        return (
            <div className={"form-group form-group-element " + name}>
                <p>{this.props.label}</p>
                {radio}
                <div className="loader-form d-none" title={"sauvegarde en cours"}>
                    <FontAwesomeIcon icon={faSpinner} spin/>
                </div>
                <div className="loader-finish-form d-none" title={"sauvegarde effectué"}>
                    <FontAwesomeIcon icon={faCheck}/>
                </div>
            </div>
        )
    }
}

export class Birthday extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            year: "",
            month: "",
            day: ""
        }
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
        const event = {
            target: {
                name: "birthday",
                value: (e.target.name === "year" ? e.target.value : this.state.year)+"-"+(e.target.name === "month" ? e.target.value : this.state.month)+"-"+(e.target.name === "day" ? e.target.value : this.state.day)
            }
        };
        this.props.onChange(event);
    }

    handleBlur() {
        const event = {
            target: {
                name: "birthday",
                value: this.state.year+"-"+this.state.month+"-"+this.state.day
            }
        };
        this.props.onBlur(event);
    }

    componentWillMount() {
        let date = this.props.value;
        date = date.split('-');
        this.setState({day:date[2], month: date[1], year: date[0]})
    }

    render() {
        return (
            <div className={"form-group form-group-element " + this.props.name}>
                <div className={"col-md-12"}>
                    <div className={"row"}>
                        <div className={"col-md-4 pl-md-0"}>
                            <input type="text"
                                   className="form-control"
                                   placeholder={"JJ"}
                                   value={this.state.day}
                                   name={"day"}
                                   onChange={this.handleChange.bind(this)}
                                   onBlur={this.handleBlur.bind(this)}
                            />
                        </div>
                        <div className={"col-md-4"}>
                            <input type="text"
                                   className="form-control"
                                   placeholder={"MM"}
                                   value={this.state.month}
                                   name={"month"}
                                   onChange={this.handleChange.bind(this)}
                                   onBlur={this.handleBlur.bind(this)}
                            />
                        </div>
                        <div className={"col-md-4 pr-md-0"}>
                            <input type="text"
                                   className="form-control"
                                   placeholder={"YYYY"}
                                   value={this.state.year}
                                   name={"year"}
                                   onChange={this.handleChange.bind(this)}
                                   onBlur={this.handleBlur.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                <div className="loader-form d-none" title={"sauvegarde en cours"}>
                    <FontAwesomeIcon icon={faSpinner} spin/>
                </div>
                <div className="loader-finish-form d-none" title={"sauvegarde effectué"}>
                    <FontAwesomeIcon icon={faCheck}/>
                </div>
            </div>
        )
    }
}
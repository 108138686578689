import React from "react";
import {withRouter} from 'react-router-dom';
import Forms from "../global/Forms";
import Helpers from "../Helpers";
import ReactGA from "react-ga";


class Register extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            baseUrl: "https://admin.biopromo.fr",
            canRender: false,
            models: {
                firstName: "",
                lastName: "",
                mail: "",
                address: "",
                zipcode: "",
                town: "",
                country: "",
                phoneNumber: "",
                cellPhone: "",
                foodHabit: "",
                aboutUs: "",
                gender: "",
                birthday: "",
            },
            base: {
                firstName: "",
                lastName: "",
                mail: "",
                address: "",
                zipcode: "",
                town: "",
                country: "",
                phoneNumber: "",
                cellPhone: "",
                foodHabit: "",
                aboutUs: "",
                gender: "",
                birthday: "",
            },
            inputs: [
                {
                    name: "firstName",
                    label: "Prenom",
                    placeholder: "Prenom",
                    type: "text"
                },
                {
                    name: "lastName",
                    label: "Nom",
                    placeholder: "Nom",
                    type: "text"
                },
                {
                    name: "birthday",
                    label: "Anniversaire",
                    type: "birthday"
                },
                {
                    name: "mail",
                    label: "Email",
                    placeholder: "Email",
                    type: "text"
                },
                {
                    name: "password",
                    label: "Mot de passe",
                    placeholder: "Mot de passe",
                    type: "password"
                },
                {
                    name: "password1",
                    label: "Confirmation de votre mot de passe",
                    placeholder: "Confirmation de votre mot de passe",
                    type: "password"
                },
                {
                    name: "address",
                    label: "Adresse",
                    placeholder: "Adresse",
                    type: "text"
                },
                {
                    name: "zipcode",
                    label: "Code postal",
                    placeholder: "Code postal",
                    type: "text"
                },
                {
                    name: "town",
                    label: "Ville",
                    placeholder: "Ville",
                    type: "text"
                },
                {
                    name: "country",
                    label: "Pays",
                    type: "select",
                    options: [
                        {
                            label: 'France (la)',
                            value: 'France (la)',
                        },
                        {
                            label: 'Belgique (la)',
                            value: 'Belgique (la)',
                        },
                        {
                            label: 'Suisse (la)',
                            value: 'Suisse (la)',
                        },
                        {
                            label: 'Italie (l\')',
                            value: 'Italie (l\')',
                        }
                    ]
                },
                {
                    name: "phoneNumber",
                    label: "Numéro de téléphone",
                    placeholder: "Numéro de téléphone",
                    type: "text"
                },
                {
                    name: "cellPhone",
                    label: "Numéro de cellulaire",
                    placeholder: "Numéro de cellulaire",
                    type: "text"
                },
                {
                    name: "gender",
                    label: "Civilité",
                    type: "radio",
                    inputs: [
                        {
                            label: "Homme",
                            value: "H"
                        },
                        {
                            label: "Femme",
                            value: "F"
                        }
                    ],
                },
            ]
        };
    }

    changeForm(data) {
        let models = this.state.models;
        models[data.name] = data.value;
        this.setState({models: models});
    }

    changeBlur(key) {
        return false;
    }

    submitForm() {
        let models = this.state.models;
        if (models.password !== models.password1) {
            alert("Mots de passe non identique");
            return false;
        } else {
            models['mobileOS'] = 'androids';
            models['mobileModel'] = 'p20pro';
            models['mobileVersion'] = '9.23';
            models['applicationVersion'] = '1.254';
            const url = "https://api.biopromo.fr/v1/user";
            Helpers.request(url, "POST", this.state.models)
                .then((response) => {
                    if (response.status === 0) {
                        this.props.handleLogin(response.jwt);
                    }else{
                        alert(response.errorMsg);
                    }
                })
        }
    }

    render() {

        const {models, inputs} = this.state;
        ReactGA.initialize('UA-24145657-10');
        ReactGA.pageview(window.location.pathname + window.location.search);
        return (
            <div className="container pt-5">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="back-title">Inscription</h1>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 pt-3">
                    <Forms
                        models={models}
                        inputs={inputs}
                        submitButton={true}
                        submitForm={this.submitForm.bind(this)}
                        changeForm={this.changeForm.bind(this)}
                        changeBlur={this.changeBlur.bind(this)}
                    />
                </div>
            </div>
        )
    }
}

export default withRouter(Register)
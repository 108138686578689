import React from "react"
import $ from "jquery";
import Helpers from "../../../Helpers";
import Forms from "../../../global/Forms";

export default class General extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canRender: false,
            models: {
                firstName: "",
                lastName: "",
                mail: "",
                address: "",
                zipcode: "",
                town: "",
                country: "",
                phoneNumber: "",
                cellPhone: "",
                foodHabit: "",
                aboutUs: "",
                gender: "",
                birthday: "",
            },
            base: {
                firstName: "",
                lastName: "",
                mail: "",
                address: "",
                zipcode: "",
                town: "",
                country: "",
                phoneNumber: "",
                cellPhone: "",
                foodHabit: "",
                aboutUs: "",
                gender: "",
                birthday: "",
            },
            inputs: [
                {
                    name: "firstName",
                    label: "Prenom",
                    placeholder: "Prenom",
                    type: "text"
                },
                {
                    name: "lastName",
                    label: "Nom",
                    placeholder: "Nom",
                    type: "text"
                },
                {
                    name: "birthday",
                    label: "Anniversaire",
                    type: "birthday"
                },
                {
                    name: "mail",
                    label: "Email",
                    placeholder: "Email",
                    type: "text"
                },
                {
                    name: "address",
                    label: "Adresse",
                    placeholder: "Adresse",
                    type: "text"
                },
                {
                    name: "zipcode",
                    label: "Code postal",
                    placeholder: "Code postal",
                    type: "text"
                },
                {
                    name: "town",
                    label: "Ville",
                    placeholder: "Ville",
                    type: "text"
                },
                {
                    name: "country",
                    label: "Pays",
                    type: "select",
                    options: [
                        {
                            label: 'France (la)',
                            value: 'France (la)',
                        },
                        {
                            label: 'Belgique (la)',
                            value: 'Belgique (la)',
                        },
                        {
                            label: 'Suisse (la)',
                            value: 'Suisse (la)',
                        },
                        {
                            label: 'Italie (l\')',
                            value: 'Italie (l\')',
                        }
                    ]
                },
                {
                    name: "phoneNumber",
                    label: "Numéro de téléphone",
                    placeholder: "Numéro de téléphone",
                    type: "text"
                },
                {
                    name: "cellPhone",
                    label: "Numéro de cellulaire",
                    placeholder: "Numéro de cellulaire",
                    type: "text"
                },
                {
                    name: "gender",
                    label: "Civilité",
                    type: "radio",
                    inputs: [
                        {
                            label: "Homme",
                            value: "H"
                        },
                        {
                            label: "Femme",
                            value: "F"
                        }
                    ],
                },
            ]
        };
    }

    changeForm(data) {
        let models = this.state.models;
        models[data.name] = data.value;
        this.setState({models: models});
    }

    doUpdate(key, value) {
        let data = {};
        data[key] = value;
        if (key === "country") {
            if (value === "France (la)") {
                data[key] = 78;
            }else if (value === "Belgique (la)") {
                data[key] = 24;
            }else if (value === "Suisse (la)") {
                data[key] = 218;
            }else if (value === "Italie (l')") {
                data[key] = 112;
            }
        }
        $('.' + key).find(".loader-finish-form").addClass("d-none");
        $('.' + key).find(".loader-form").removeClass("d-none");
        const url = "https://api.biopromo.fr/v1/user";
        Helpers.request(url, "PUT", data)
            .then((response) => {
                let base = this.state.base;
                base[key] = value;
                this.setState({base: base})
                $('.' + key).find(".loader-form").addClass("d-none");
                $('.' + key).find(".loader-finish-form").removeClass("d-none");
                setTimeout(() => {
                    $('.' + key).find(".loader-finish-form").addClass("d-none")
                }, 3000)
            })
            .catch((response) => {
                $('.' + key).find(".loader-form").addClass("d-none");
            })
    }

    submitForm() {
        $(".loader-finish-form").addClass("d-none");
        $(".loader-form").removeClass("d-none");
        const url = "https://api.biopromo.fr/v1/user";
        Helpers.request(url, "PUT", this.state.models)
            .then((response) => {
                $(".loader-form").addClass("d-none");
                $(".loader-finish-form").removeClass("d-none");
                setTimeout(() => {
                    $(".loader-finish-form").addClass("d-none")
                }, 3000)
            })
    }

    changeBlur(key) {
        if (this.state.models[key] !== this.state.base[key]) {
            this.doUpdate(key, this.state.models[key]);
        }
    }

    componentWillMount() {
        const url = "https://api.biopromo.fr/v1/user";
        Helpers.request(url, "GET")
            .then((response) => {
                if (response.status === 0) {
                    let models = this.state.models;
                    let baseModels = this.state.base;
                    let user = response.user;
                    if (typeof (user) === "object") {
                        for (const key in models) {
                            if (typeof (user[key]) !== "undefined") {
                                models[key] = user[key];
                                baseModels[key] = user[key];
                            }
                        }
                        this.setState({models: models, base: baseModels, canRender: true})
                    }
                }
            });
    }

    render() {
        const {canRender, models, inputs} = this.state;

        if (!canRender) {

            return null;
        }

        return (

            <div className="col-md-8 pt-3">
                <Forms
                    models={models}
                    inputs={inputs}
                    submitButton={true}
                    changeForm={this.changeForm.bind(this)}
                    changeBlur={this.changeBlur.bind(this)}
                    submitForm={this.submitForm.bind(this)}
                />
            </div>

        )
    }

}
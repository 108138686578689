import React from "react"
import Helpers from "../../Helpers";
import Card from "./Deals/Card";
import ReactGA from "react-ga";

export default class DealsOffers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deals: [],
            baseDeals: [],
            input: "",
        }
    }

    getProducts() {
        const url = "https://api.biopromo.fr/v1/deal";
        Helpers.request(url, "POST")
            .then((response) => {
                if (response.status === 0) {
                    this.setState({deals: response.deals, baseDeals: response.deals})
                }
            });
    };

    handleChange(event) {
        const baseValue = event.target.value;
        const value = baseValue.toLowerCase().replace(/ /g, "");
        let baseDeals = this.state.baseDeals;
        if (value === "") {
            this.setState({deals: baseDeals, input: baseValue});
        } else {
            let deals = {};
            for (const deal in baseDeals) {
                const current = baseDeals[deal];
                if (current.name.toLowerCase().replace(/ /g, "").indexOf(value) !== -1) {
                    deals[deal] = current;
                }
            }
            this.setState({deals: deals, input: baseValue});
        }
    }

    componentDidMount() {
        ReactGA.initialize('UA-24145657-10');
        ReactGA.pageview(window.location.pathname + window.location.search);
        this.getProducts();
    }

    render() {
        let content = [];
        const deals = this.state.deals;
        let key = 0;
        if (typeof (deals) === "object" && Object.keys(deals).length > 0) {
            for (const deal in deals) {
                const cardKey = 'deal-card-' + key;
                content = [...content, <Card key={cardKey} data={deals[deal]}/>];
                key++;
            }
        }
        return (
            <div className="container pt-5">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            <h1 className="back-title">Offres en cours</h1>
                        </div>
                        <div className="col-md-6 ">
                            <input name="search" className="search" placeholder="Recherche" value={this.state.input}
                                   onChange={this.handleChange.bind(this)}/>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row">
                        {content}
                    </div>
                </div>
            </div>
        )
    }
}
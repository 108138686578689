import React from 'react';
import selection_ccm from '../../assets/selection_ccm.png';
import acheter_ccm from '../../assets/acheter_ccm.png';
import photo_ccm from '../../assets/photo_ccm.png';
import remboursement_ccm from '../../assets/remboursement_ccm.png';
import {PageTitle} from '../global/Others'
import ReactGA from "react-ga";

const Block = ({src, nb, text}) => {
    return (
        <div className="col-md-12 clearfix mt-5">
            <div className="img-ccm">
                <img src={src} alt={""}/>
            </div>
            <div className="text-content-ccm">
                <div className="col-md-12">
                    <p className="number">{nb}</p>
                    <p className="text_ccm">{text}</p>
                </div>
            </div>
        </div>
    );
};

function CommentCaMarche() {

    ReactGA.initialize('UA-24145657-10');
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (<div className="container pt-5">
        <PageTitle title={"Comment ça marche"} />
        <div className="col-md-12">
            <div className="row">
                <Block
                    src={selection_ccm}
                    nb={1}
                    text={"Choisissez les produits qui vous intéressent parmi les offres proposées"}
                />
                <Block
                    src={acheter_ccm}
                    nb={2}
                    text={"Rendez-vous dans votre magasin bio et achetez vos produits"}
                />
                <Block
                    src={photo_ccm}
                    nb={3}
                    text={"Ajoutez une photo de votre ticket de caisse"}
                />
                <Block
                    src={remboursement_ccm}
                    nb={4}
                    text={"Nous nous occupons du reste ! Vous serez ensuite remboursé sur votre compte Paypal(~30jours) ou compte bancaire(~7jours)"}
                />
            </div>
        </div>
        <div className="col-md-12 mt-5">
            <p className="text-center mt-5 mb-5">
                Consultez régulièrement l'application, de nouvelles offres sont en ligne toutes les semaines ! :)
            </p>
        </div>
    </div>)
}

export default CommentCaMarche
import React from 'react';
import {
    Switch,
    BrowserRouter as Router,
    Route
} from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import './App.css';

import Header from './component/global/Header';
import Footer from './component/global/Footer';
import Home from './component/page/Home';
import Offers from './component/page/Offers';
import DealsOffers from './component/page/Session/DealsOffers';
import CommentCaMarche from './component/page/CommentCaMarche';
import CentreAide from './component/page/CentreAide';
import Login from './component/page/Login';
import Register from './component/page/Register';
import Account from './component/page/Session/Account'
import Remboursements from './component/page/Session/Remboursements'
import Deal from './component/page/Session/Deals/Deal'

export default class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLogged: false,
        };
    }

    handleLogin(data) {
        localStorage.setItem("session", data);
        window.jwt = data;
        this.setState({isLogged: true});
    }

    handleLogout() {
        if (typeof (window.jwt) !== "undefined") {
            delete window.jwt;
        }
        if (localStorage.getItem("session") !== null) {
            localStorage.removeItem("session");
            this.setState({isLogged: false})
        }
    }

    componentDidMount() {
	document.title = "Biopromo";
        if (localStorage.getItem("session") !== null) {
            window.jwt = localStorage.getItem("session");
            this.setState({isLogged: true})
        }
    }

    render() {
        let render = [];
        if (!this.state.isLogged) {
            render = [
                <Route path="/offresencours" key={"app-path-0"}>
                    <Offers/>
                </Route>,
                <Route path="/commentcamarche" key={"app-path-1"}>
                    <CommentCaMarche/>
                </Route>,
                <Route path="/centreaide" key={"app-path-2"}>
                    <CentreAide/>
                </Route>,
                <Route path="/register" key={"app-path-2"}>
                    <Register
                        handleLogin={this.handleLogin.bind(this)}
                    />
                </Route>,
                <Route path="/login" key={"app-path-3"}>
                    <Login
                        handleLogin={this.handleLogin.bind(this)}
                        isLogged={this.state.isLogged}
                    />
                </Route>,
                <Route path="" key={"app-path-4"}>
                    <Home/>
                </Route>
            ];
        } else {
            render = [
                <Route path="/account/:page?" key={"app-path-2"}>
                    <Account/>
                </Route>,
                <Route path="/remboursements" key={"app-path-3"}>
                    <Remboursements/>
                </Route>,
                <Route path="/login" key={"app-path-4"}>
                    <Login
                        handleLogin={this.handleLogin.bind(this)}
                        isLogged={this.state.isLogged}
                    />
                </Route>,
                <Route path="/deal/:id" key={"app-path-0"}>
                    <Deal/>
                </Route>,
                <Route path="/" key={"app-path-1"}>
                    <DealsOffers/>
                </Route>,
            ]
        }
        return (
            <Router>
                <div className="container-fluid pl-0 pr-0">
                    <Header isLogged={this.state.isLogged} handleLogout={this.handleLogout.bind(this)}/>
                    <div id={"baseContent"}>
                        <Switch>
                            {render}
                        </Switch>
                    </div>
                    <Footer/>
                </div>
            </Router>
        );
    }
}

import React from "react"
import Helpers from "../../../Helpers";

export default class CardTicketForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: {},
            base64: {
                ticket: ""
            },
        }
    }

    readFileAsync(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsDataURL(file);
        })
    }

    async base64(file, base64, name) {
        try {
            const b64 = await this.readFileAsync(file);
            this.validTicket(b64);
        } catch (err) {

        }
    }

    validTicket(b64) {
        let {base64} = this.state;
        base64.ticket = b64;
        this.setState({base64: base64})
    }

    handleFile(e) {
        const file = e.target.files[0];
        const name = e.target.name;
        let {base64} = this.state;
        this.base64(file, base64, name);
    }

    closeWindow(e) {
        if (e.target.id === "ticket-forms-inputs") {
            this.props.closeInputForm(false);
        }
    }

    submitFile() {
        let scan = this.state.base64.ticket.split(",");
        if (scan.length < 2) {
            alert("Fichier invalide");
            return false;
        }else{
            Helpers.request("https://api.biopromo.fr/v1/refund", "PUT", {
                action: "RESCANTICKET",
                id_refund: this.props.id_refund,
                scan: scan[1]
            })
                .then((response) => {
                    if (response.status === 0) {
                        this.props.closeInputForm(true);
                    }
                });
        }
    }

    render() {
        return (
            <div id={"ticket-forms-inputs"} onClick={this.closeWindow.bind(this)}>
                <div id={"ticket-forms"}>
                    <div className="container">
                        <div className={"col-md-12"}>
                            <div className={"card p-4"}>
                                <div className="col-md-12">
                                    <h4>Capture de votre ticket de caisse:</h4>
                                    <div className="col-md-12 pb-4">
                                        <div className="row">
                                            <input
                                                type="file"
                                                name="ticket"
                                                value={this.state.files.ticket}
                                                onChange={this.handleFile.bind(this)}
                                            />
                                        </div>
                                    </div>
                                    {this.state.base64.ticket !== "" ?
                                        <div className="col-md-12 pb-4">
                                            <div className="row" id={"ticket-content-img"}>
                                                <img className="ticket-img"
                                                     alt={""}
                                                     src={this.state.base64.ticket}/>
                                            </div>
                                            <div className={"col-md-12 pt-4"}>
                                                <div className={"row"}>
                                                    <div className={"col-md-6 text-center"}>
                                                        <button className={"btn btn-danger"} onClick={() => {
                                                            this.props.closeInputForm(false)
                                                        }}>Annuler
                                                        </button>
                                                    </div>
                                                    <div className={"col-md-6 text-center"}>
                                                        <button className={"btn btn-success"}
                                                                onClick={this.submitFile.bind(this)}>Valider
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
import React from "react"
import Forms from "../../../global/Forms";
import Helpers from "../../../Helpers";

export default class Password extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            canRender: false,
            invalid: false,
            models: {
                password: "",
                password1: "",
            },
            inputs: [
                {
                    name: "password",
                    label: "Mot de passe",
                    placeholder: "Mot de passe",
                    type: "password"
                },
                {
                    name: "password1",
                    label: "Confirmation de votre mot de passe",
                    placeholder: "Confirmation de votre mot de passe",
                    type: "password"
                },
            ]
        };
    }

    changeForm(data) {
        let models = this.state.models;
        models[data.name] = data.value;
        this.setState({models: models});
    }

    submitForm() {
        if (this.state.models.password === "" || this.state.models.password1 === "" || this.state.models.password !== this.state.models.password1) {
            this.setState({invalid: true});
            return false;
        }else{
            const url = "https://api.biopromo.fr/v1/user";
            Helpers.request(url, "PUT", {password: this.state.models.password})
                .then((response) => {

                })
        }
    }

    render() {

        const {models, inputs} = this.state;
        return (
            <div className="col-md-8 pt-3">
                {this.state.invalid ? <p className={"text-danger"}>Mots de passe invalides</p> : null}
                <Forms
                    models={models}
                    inputs={inputs}
                    submitButton={this.state.models.password !== "" && this.state.models.password !== ""}
                    changeForm={this.changeForm.bind(this)}
                    submitForm={this.submitForm.bind(this)}
                />
            </div>
        );
    }
}
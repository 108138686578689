import React from 'react';
import Card from './offers/Card';
import ReactGA from "react-ga";

export default class Offers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            deals: [],
            baseDeals: [],
            input: "",
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        ReactGA.initialize('UA-24145657-10');
        ReactGA.pageview(window.location.pathname + window.location.search);
        this.getProducts();
    }

    getProducts() {
        fetch("https://admin.biopromo.fr/offers")
            .then(res => res.json())
            .then(
                (result) => {
                    if (!!result.aDeals) {
                        this.setState({deals: result.aDeals.listing, baseDeals: result.aDeals.listing})
                    }
                },
                (error) => {

                }
            )
    };

    handleChange(event) {
        const baseValue = event.target.value;
        const value = baseValue.toLowerCase().replace(/ /g, "");
        let baseDeals = this.state.baseDeals;
        if (value === "") {
            this.setState({deals: baseDeals, input: baseValue});
        } else {
            let deals = {};
            for (const deal in baseDeals) {
                const current = baseDeals[deal];
                if (current.name.toLowerCase().replace(/ /g, "").indexOf(value) !== -1) {
                    deals[deal] = current;
                }
            }
            this.setState({deals: deals, input: baseValue});
        }
    }

    render() {
        let content = [];
        const deals = this.state.deals;
        let key = 0;
        if (typeof (deals) === "object" && Object.keys(deals).length > 0) {
            for (const deal in deals) {
                const cardKey = 'deal-card-' + key;
                content = [...content, <Card key={cardKey} data={deals[deal]}/>];
                key++;
            }
        }
        return (
            <div className="container pt-5">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            <h1 className="back-title">Offres en cours</h1>
                        </div>
                        <div className="col-md-6 ">
                            <input name="search" className="search" placeholder="Recherche" value={this.state.input}
                                   onChange={this.handleChange}/>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row">
                        {content}
                    </div>
                </div>
            </div>
        );
    }
}

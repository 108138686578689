import React from 'react'
import Modal from 'react-modal';
import ReactHtmlParser from 'react-html-parser';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        width: 'auto',
        height: 'auto',
        maxWidth: '500px',
        maxHeight: '500px',
        overflow: 'hidden',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

export default class Card extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            baseUrl: "https://biopromo.fr",
            modalIsOpen: false
        };
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {

    }

    openModal() {
        this.setState({modalIsOpen: true});
        document.body.classList.add("overflow-hidden");
    }

    afterOpenModal() {

    }

    closeModal() {
        this.setState({modalIsOpen: false});
        document.body.classList.remove("overflow-hidden");
    }

    static makeImgUrl(id) {
        let path = '/_img/deals';
        let charLength = id.length;
        let i = 0;
        while (i < 3) {
            let current = id.substring((charLength - 1), charLength);
            if (current === "") {
                current = "0";
            }
            path += '/' + current;
            charLength--;
            i++;
        }

        return path + "/" + id + ".jpg";
    }

    render() {
        let data = this.props.data;
        const {baseUrl} = this.state;
        let content = [];
        content = [...content, <div className="offre col-md-3" key={data.id_deal + "-card"} onClick={this.openModal}>
            <img src={baseUrl + Card.makeImgUrl(data.id_deal)} className="img_pdt" alt={""}/>
            <div className="reduc_div">
                <p className="reduc">
                    <img className="picto" alt="" src={baseUrl + "/_img/cochon.png"}/>
                    <br/>
                    <span className="amount">- {parseFloat(data.amount_centime) / 100}€ </span>
                    <br/>
                    <span className="msg-deal">Remboursé</span>
                </p>
            </div>
            <div className={"marque_name"}>
                <p className="title_pdt">{data.name}</p>
            </div>
        </div>];
        if (this.state.modalIsOpen) {
            let modalContent = <div className="row">
                    <div className="col-8">
                        <img className="img_modal" alt="" src={baseUrl + Card.makeImgUrl(data.id_deal)}/>
                    </div>
                    <div className="col-4 intermodal">
                        <div className="modal-price-content">
                            <p className="reduc_modal">
                                <img className="picto" alt="" src={baseUrl + "/_img/cochon.png"}/><br/>
                                <span className="amount">- {parseFloat(data.amount_centime) / 100}€ </span>
                            </p>
                            <p className="msg-deal_modal"><span>Remboursé</span></p>
                        </div>
                    </div>
                    <div className="col-12 inside">
                        <p className="title_pdt">{data.name}</p>
                        {ReactHtmlParser(data.html)}
                    </div>
                </div>
            ;
            content = [...content, <Modal
                key={data.id_deal + "-modal"}
                isOpen={this.state.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={customStyles}
                //contentLabel={data.html}
            >
                {modalContent}
            </Modal>]
        }
        return (content);
    }
};

import React from 'react';
import {Link, Redirect} from "react-router-dom";
import $ from "jquery";
import Helpers from "../Helpers"
import ReactGA from "react-ga";

export default class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sendEmail: "",
            email: "",
            password: "",
            apiUrl: "https://api.biopromo.fr/v1/",
            isLogged: false,
            mailSucess: 0,
        };
    }

    sendNewEmail(e) {
        e.preventDefault();
        const url = this.state.apiUrl + "login";
        Helpers.request(url, "PUT", {
            mail: this.state.sendEmail
        })
            .then((response) => {
                if (response.status === 0) {
                    this.setState({mailSucess: 1});
                }else{
                    this.setState({mailSucess: 2});
                }
            });
    }

    changeInput(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    login(e) {
        e.preventDefault();
        const data = {
            mail: this.state.email,
            password: this.state.password,
            mobileOS: 'androids',
            mobileModel: 'p20pro',
            mobileVersion: '9.23',
            applicationVersion: '1.254'
        };
        const url = this.state.apiUrl + "login";
        Helpers.request(url, "POST", data)
            .then((response) => {
                if (response.status === 0) {
                    this.props.handleLogin(response.jwt);
                }else{
                    alert(response.errorMsg);
                }
            });
    }

    componentDidMount() {
        ReactGA.initialize('UA-24145657-10');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        let render = null;
        if (this.props.isLogged) {
            render = <Redirect to="/"/>;
        } else {
            render = <div className="container pt-5">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="back-title">Login</h1>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="col-md-9 mt-5">
                        <form className="login_form" onSubmit={this.login.bind(this)}>
                            <div className="form-group">
                                <label id="label_login" htmlFor="login">Login</label>
                                <input type="text" name="email" className="form-control" id="login" placeholder="login"
                                       value={this.state.email}
                                       onChange={this.changeInput.bind(this)}
                                       required="required"/>
                            </div>
                            <div className="form-group">
                                <label id="label_pass" htmlFor="password">Password</label>
                                <input type="password" name="password" className="form-control" id="password"
                                       value={this.state.password}
                                       onChange={this.changeInput.bind(this)}
                                       placeholder="mot de passe" required="required"/>
                                <div className="form-group row">
                                    <div className="col-sm-6 col-xs-6">
                                        <button className="btn btn-default inscription_submit">Se connecter</button>
                                    </div>
                                    <div className="col-sm-5 col-xs-5">
                                        <Link className="btn btn-default login_submit"
                                              to="/register">S'inscrire</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="col-md-9 mt-5">
                        <form className="login_form" action="#" id="rappel_mdp" onSubmit={this.sendNewEmail.bind(this)}>
                            <div className="form-group">
                                <label id="label_login" htmlFor="login">Mot de passe oublié</label>
                                <input type="text" name="sendEmail" className="form-control" id="sendEmail"
                                       onChange={this.changeInput.bind(this)}
                                       placeholder="Adresse email" required="required"/>
                            </div>
                            {this.state.mailSucess === 1 &&
                            <div id="emailSucess" className="col-12 email-sucess">
                                Un email de renouvellement de mot de passe a été envoyé
                            </div>
                            }
                            {this.state.mailSucess === 2 &&
                            <div id="emailSucess" className="col-12 email-fail">
                                Une erreur est survenue.
                            </div>
                            }
                            <div className="form-group">
                                <div className="form-group row">
                                    <div className="col-sm-5 col-xs-5">
                                        <button type="submit" className="btn btn-default inscription_submit"
                                                value={this.state.sendEmail}
                                        >Envoyer
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        }
        return (render)
    }
}
import React from "react"
import Helpers from "../../../Helpers";


export default class RefundCard extends React.Component {

    static convertDatetime(time) {
        const date = time.split(" ")["0"].split("-");

        return date[2] + "/" + date[1] + "/" + date[0]
    }

    cancelDeal(e) {
        Helpers.request("https://api.biopromo.fr/v1/refund", "PUT", {action: "CANCEL", id_refund: e.target.value})
            .then((response) => {
                if (response.status === 0) {
                    this.props.reloadDeals(true);
                }
            });
    }

    render() {
        const props = this.props.value;
        const {status} = props;
        let statusDiv = null;
        let actionDiv = null;
        if (status === "cancel") {
            statusDiv = <div className={"status-refund ko-refund"}><span/><p>Annulé</p></div>;
        } else if (status === "pending") {
            statusDiv = <div className={"status-refund tovalid-refund"}><span/><p>En cours</p></div>;
            actionDiv = <div className={"action-refund"}>
                <button className={"btn btn-warning"} onClick={this.cancelDeal.bind(this)}
                        value={props.id_refund}>Annuler
                </button>
            </div>;
        } else if (status === "OK") {
            statusDiv = <div className={"status-refund valid-refund"}><span/><p>Accepté</p></div>;
        } else if (status === "KO") {
            statusDiv = <div className={"status-refund cancel-refund"}><span/><p>Refusé</p></div>;
        } else if (status === "need_ticket") {
            statusDiv = <div className={"status-refund ticket-refund"}><span/><p>A renvoyer</p></div>;
            actionDiv = <div className={"action-refund"}>
                <button className={"btn btn-primary"} onClick={() => {
                    this.props.showInputForm(props.id_refund);
                }}>Renvoyer
                </button>
            </div>;
        }

        return (
            <div className={"col-md-3 p-3"}>
                <div className={"col-md-12 p-3 refund-card"}>
                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <p className={"refund-date"}>{RefundCard.convertDatetime(props.date)}</p>
                        </div>
                        <div className={"col-md-12"}>
                            <h3>{props.dealName}</h3>
                        </div>
                        <div className={"col-md-12"}>
                            {statusDiv}
                        </div>
                    </div>
                    {actionDiv}
                </div>
            </div>
        )
    }

}
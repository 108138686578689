import React from "react"
import {withRouter} from 'react-router-dom'
import Helpers from "../../../Helpers";
import {Text} from "../../../global/Inputs"
import ReactGA from "react-ga";

class Deal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            deal: {},
            id: "",
            baseUrl: "https://biopromo.fr",
            canView: false,
            doDeal: false,
            send: {
                ean: "",
                base64: "",
                scanEan: "",
                id_magasin: "",
                cp: ""
            },
            files: {},
            shops: []
        };

        this.getProducts = this.getProducts.bind(this);
        this.dealForm = this.dealForm.bind(this);
        this.handleChangeCp = this.handleChangeCp.bind(this);
        this.getShop = this.getShop.bind(this);
        this.renderShops = this.renderShops.bind(this);
    }

    getProducts(id) {
        const url = `https://api.biopromo.fr/v1/deal?id=${id}`;
        Helpers.request(url, "GET")
            .then((response) => {
                if (response.status === 0) {
                    let state = this.state;
                    state.deal = response.deal;
                    state.id = id;
                    state.canView = true;
                    state.send.ean = response.deal.produits[0].ean;
                    this.setState(state)
                }
            }).catch((err) => {

        });
    };

    static makeImgUrl(id) {
        let path = '/_img/deals';
        id = id.toString();
        let charLength = id.length;
        let i = 0;
        while (i < 3) {
            let current = id.substring((charLength - 1), charLength);
            if (current === "") {
                current = "0";
            }
            path += '/' + current;
            charLength--;
            i++;
        }

        return path + "/" + id + ".jpg";
    }

    componentDidMount() {

        ReactGA.initialize('UA-24145657-10');
        ReactGA.pageview(window.location.pathname + window.location.search);

        const {id} = this.props.match.params;
        this.getProducts(id);
    }

    addDeal() {
        this.setState({doDeal: true})
    }

    removeForm(e) {
        if (e.target.id === "deal-form-content") {
            this.setState({doDeal: false})
        }
    }

    closeInputForm() {
        this.setState({doDeal: false});
    }

    handleChange(event) {
        let {send} = this.state;
        send[event.target.name] = event.target.value;
        this.setState({send})
    }

    readFileAsync(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsDataURL(file);
        })
    }

    async base64(file, name) {
        try {
            const b64 = await this.readFileAsync(file);
            this.validTicket(b64, name);
        } catch (err) {

        }
    }

    validTicket(b64, name) {
        let {send} = this.state;
        if (name === "ean") {
            send.scanEan = b64;
        } else {
            send.base64 = b64;
        }
        this.setState({send})
    }

    handleFile(e) {
        const file = e.target.files[0];
        this.base64(file, e.target.name);
    }

    submitFile() {
        let eanScan = this.state.send.scanEan.split(",");
        let scan = this.state.send.base64.split(",");
        if (scan.length < 2 || eanScan.length < 2) {
            alert("fichiers invalides");
            return false;

        } else {
            Helpers.request("https://api.biopromo.fr/v1/refund", "POST", {
                EAN: this.state.send.ean,
                scanEAN: eanScan[1],
                id_shop: this.state.send.id_magasin,
                id_deal: this.state.id,
                scan: scan[1]
            })
                .then((response) => {
                    if (response.status === 0) {
                        //this.setState({doDeal: false});
                        this.props.history.push('/remboursements')
                    } else if ([-1, -3, -4, -5].includes(response.status)) {
                        alert(response.errorMsg);
                    }
                });
        }
    }

    getShop(cp) {
        const url = `https://api.biopromo.fr/v1/shop?zipcode=${cp}`;
        Helpers.request(url, "GET")
            .then((response) => {
                if (response.status === 0) {
                    let shops = [];
                    const responseShop = response.shops;
                    for (const key in responseShop) {
                        const value = responseShop[key];
                        if (value.id !== 0) {
                            shops = [...shops, value];
                        }
                    }
                    if (shops.length > 0) {
                        this.setState({shops})
                    }
                }
            });
    }

    handleChangeCp(event) {
        let {send} = this.state;
        const value = event.target.value;
        send[event.target.name] = value;
        this.setState({send});
        if (value.length === 5) {
            this.getShop(value);
        } else {
            let state = this.state;
            state.shops = [];
            state.send.id_magasin = "";
            this.setState(state);
        }
    }

    renderShops() {
        let shops = [];
        if (this.state.shops.length > 0) {
            for (const [key, value] of Object.entries(this.state.shops)) {
                shops = [...shops,
                    <button
                        name={"id_magasin"}
                        value={value.id}
                        className={parseInt(this.state.send.id_magasin) === value.id ? "selected-shop" : ""}
                        onClick={this.handleChange.bind(this)}
                        key={"shop-" + key}>{value.name}</button>
                ]
            }
        }

        return shops;
    }

    dealForm() {

        return <div id={"deal-form-content"} key={"deal-2"} onClick={this.removeForm.bind(this)}>
            <div className={"container"}>
                <div className={"col-md-8"} id={"deal-form-content-elem"}>
                    <div className={"card p-4 form-content-deal"}>
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <h5>1/ Importez une photo du code-barre de votre produit :</h5>
                                <div className="col-md-12 pb-4">
                                    <div className="row">
                                        <input
                                            type="file"
                                            name="ean"
                                            value={this.state.files.ean}
                                            onChange={this.handleFile.bind(this)}
                                        />
                                    </div>
                                </div>
                                {this.state.send.scanEan !== "" ?
                                    <div className="col-md-12 pb-4">
                                        <div className="row" id={"ticket-content-img"}>
                                            <img className="ticket-img"
                                                 alt={""}
                                                 src={this.state.send.scanEan}/>
                                        </div>
                                    </div> : null}
                            </div>

                            <div className={"col-md-12"}>
                                <h5>2/ Entrez l'ean de votre produit ( situé au niveau du code-barre ) :</h5>
                                <div className={"form-group"}>
                                    <Text
                                        value={this.state.send.ean}
                                        name={"ean"}
                                        placeholder={"Ean de votre produit"}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </div>
                            </div>

                            <div className={"col-md-12"}>
                                <h5>3/ Importez une photo de votre ticket de caisse :</h5>
                                <div className="col-md-12 pb-4">
                                    <div className="row">
                                        <input
                                            type="file"
                                            name="ticket"
                                            value={this.state.files.ticket}
                                            onChange={this.handleFile.bind(this)}
                                        />
                                    </div>
                                </div>
                                {this.state.send.base64 !== "" ?
                                    <div className="col-md-12 pb-4">
                                        <div className="row" id={"ticket-content-img"}>
                                            <img className="ticket-img"
                                                 alt={""}
                                                 src={this.state.send.base64}/>
                                        </div>
                                    </div> : null}
                            </div>

                            <div className={"col-md-12"}>
                                <h5>4/ Selectionnez le code postal du magasin où vous avez acheté le produit concerné
                                    :</h5>
                                <div className={"form-group"}>
                                    <Text
                                        value={this.state.send.cp}
                                        name={"cp"}
                                        placeholder={"Code postal de votre magasin"}
                                        maxLength="5"
                                        onChange={this.handleChangeCp.bind(this)}
                                    />
                                    <div className="col-md-12 inputs-shops">
                                        {this.renderShops()}
                                    </div>
                                </div>
                            </div>

                            {this.state.send.base64 !== "" && this.state.send.scanEan !== "" && this.state.send.ean !== "" && this.state.send.id_magasin !== "" ?
                                <div className={"col-md-12 pt-4"}>
                                    <div className={"row"}>
                                        <div className={"col-md-6 text-center"}>
                                            <button className={"btn btn-danger"}
                                                    onClick={this.closeInputForm.bind(this)}>Annuler
                                            </button>
                                        </div>
                                        <div className={"col-md-6 text-center"}>
                                            <button className={"btn btn-success"}
                                                    onClick={this.submitFile.bind(this)}>Valider
                                            </button>
                                        </div>
                                    </div>
                                </div> : null}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    render() {
        const {deal, baseUrl, id, canView, doDeal} = this.state;
        if (!canView) {

            return null;
        }
        let dealForm = null;
        if (doDeal) {
            dealForm = this.dealForm();
        }
        return (
            [
                <div className="container pt-2" key={"deal-1"}>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12 pt-4">
                                <h2 className="back-title">{deal.name}</h2>
                            </div>
                            <div className="deal col-md-6">
                                <img src={baseUrl + Deal.makeImgUrl(id)} className="deal_pdt" alt={""}/>
                                <div className="reduc-deal_div">
                                    <p className="reduc">
                                        <img className="picto-deal" alt="" src={baseUrl + "/_img/cochon.png"}/>
                                        <br/>
                                        <span className="amount-deal">- {parseFloat(deal.amount_centime) / 100}€ </span>
                                        <br/>
                                        <span className="msg-deal-deal">Remboursé</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 html-deal" dangerouslySetInnerHTML={{__html: deal.html}}/>
                        </div>
                    </div>
                    <div className="col-md-12 pt-4 text-center">
                        <button className={"btn btn-success"} onClick={this.addDeal.bind(this)}>Demande de
                            remboursement
                        </button>
                    </div>
                </div>,
                dealForm
            ]
        )
    }
}

export default withRouter(Deal)
import React from 'react';
import {Link} from "react-router-dom";
import logo from '../../assets/logo.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars} from '@fortawesome/free-solid-svg-icons'

export default class Header extends React.Component {

    logOut() {
        this.props.handleLogout(true)
    }

    render() {
        let optionals = [];
        if (!this.props.isLogged) {
            optionals = [
                <li className="nav-item" key={"session-route-0"}>
                    <Link to="/login" className="nav-link">connexion</Link>
                </li>,
                <li className="nav-item" key={"session-route-1"}>
                    <Link to="/centreaide" className="nav-link">Centre d'aide</Link>
                </li>,
                <li className="nav-item" key={"session-route-2"}>
                    <Link to="/commentcamarche" className="nav-link">Comment ça marche</Link>
                </li>,
                <li className="nav-item" key={"session-route-3"}>
                    <Link to="/offresencours" className="nav-link">Offres en cours</Link>
                </li>,
            ]
        } else {
            optionals = [
                <li className="nav-item" key={"session-route-1"}>
                    <Link to="/login" className="nav-link" onClick={this.logOut.bind(this)}>Deconnexion</Link>
                </li>,
                <li className="nav-item" key={"session-route-0"}>
                    <Link to="/account" className="nav-link">Mon compte</Link>
                </li>,
                <li className="nav-item" key={"session-route-2"}>
                    <Link to="/remboursements" className="nav-link">Mes remboursements</Link>
                </li>,
                <li className="nav-item" key={"session-route-3"}>
                    <Link to="/" className="nav-link">Offres</Link>
                </li>,
            ]
        }
        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-light" id="navbarNavMain">
                <Link to="/">
                    <img src={logo}  alt={""}/>
                </Link>
                <div className={"navbar-header"} id={"nav-bar-burger"}>
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbarNav">
                        <FontAwesomeIcon icon={faBars}/>
                    </button>
                </div>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav flex-row-reverse w-100">
                        {optionals}
                    </ul>
                </div>
            </nav>
        );
    }
}

import React from "react"
import {RadioColumns, Text} from '../../../global/Inputs'
import {withRouter} from 'react-router-dom'
import IBAN from 'iban';
import Helpers from "../../../Helpers";

class Payment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            payment_method: null,
            inputs: [
                {
                    label: "Compte paypal (~30j)",
                    value: "PAYPAL"
                },
                {
                    label: "Virement bancaire (~7j)",
                    value: "RIB"
                },
                {
                    label: "Don à une oeuvre de charité",
                    value: 'ASSOS'
                },
                {
                    label: "Le Club Bio (~7j)",
                    value: "CLUB"
                }
            ],
            models: {
                iban: "",
                id_assos_partner: "",
                id_paypal: "",
                email_club: "",
                ribScan: ""
            },
            error: {
                iban: false,
                id_assos_partner: false,
                id_paypal: false,
                email_club: false,
            },
            canSave: false,
            canRender: false
        }
    }
    /*
    returnOnPreviousPage() {
        this.props.history.goBack();
    }
    */
    static validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(String(email).toLowerCase());
    }

    validEmail(e) {
        const target = e.target;
        const model = target.name;
        const value = target.value;
        let canSave = true;
        let error = this.state.error;
        if (value === "") {
            error[model] = false;
            canSave = false;
        } else {
            if (!Payment.validateEmail(value)) {
                error[model] = true;
                canSave = false;
            } else {
                error[model] = false;
                canSave = true;
            }
        }

        this.setState({error: error, canSave: canSave})
    }

    handleChange(e) {
        const target = e.target;
        let models = this.state.models;
        models[target.name] = target.value;
        this.setState({models});
    };

    validAssos(e) {
        const target = e.target;
        let models = this.state.models;
        models[target.name] = target.value;
        this.setState({models: models, canSave: (target.value !== "")});
    };

    validIban() {
        let {models, error} = this.state;
        const {iban} = models;
        let canSave = true;
        if (iban === "") {
            error.iban = false;
            canSave = false;
        } else {
            if (IBAN.isValid(iban)) {
                error.iban = false;
                canSave = true
            } else {
                error.iban = true;
                canSave = false
            }
        }

        this.setState({error: error, canSave: canSave})

    }

    handleChangeRadio(data) {
        let state = this.state;
        state[data.key] = data.value;
        state.canSave = this.canShowButtonValidate(data.value);
        this.setState(state)
    }

    canShowButtonValidate(key) {

        if (key === "RIB") {
            return IBAN.isValid(this.state.models.iban);

        } else if (key === "PAYPAL") {

            return Payment.validateEmail(this.state.models.id_paypal);

        } else if (key === "CLUB") {

            return Payment.validateEmail(this.state.models.email_club);

        } else if (key === "ASSOS") {

            return this.state.models.id_assos_partner !== "";

        }

        return false;
    }

    ibanForm() {
        return <div className="col-md-9">
            <Text
                value={this.state.models.iban}
                name={'iban'}
                placeholder={"FRXXXXXXXXXXXXXXXXXXXXXXXXX"}
                onChange={this.handleChange.bind(this)}
                onBlur={this.validIban.bind(this)}
            />
            {this.state.error.iban ? <p className="text-danger">Iban invalide</p> : null}
        </div>;
    }

    emailForm(model) {
        return <div className="col-md-9">
            <Text
                value={this.state.models[model]}
                name={model}
                placeholder={"email"}
                onChange={this.handleChange.bind(this)}
                onBlur={this.validEmail.bind(this)}
            />
            {this.state.error[model] ? <p className="text-danger">Email invalide</p> : null}
        </div>;
    }

    submitPayment() {
        const {payment_method, models} = this.state;
        let data = {payment_method: payment_method};
        if (payment_method === "RIB") {
            data['iban'] = this.state.models.iban;
        } else if (payment_method === "PAYPAL") {
            data['id_paypal'] = this.state.models.id_paypal;
        } else if (payment_method === "CLUB") {
            data['email_club'] = this.state.models.email_club;
        } else if (payment_method === "ASSOS") {
            data['id_partner'] = this.state.models.id_assos_partner;
        } else {

            return false;
        }
        const url = "https://api.biopromo.fr/v1/payment";
        Helpers.request(url, "POST", data)
            .then((response) => {

            })
    }

    componentWillMount() {
        const url = "https://api.biopromo.fr/v1/user";
        Helpers.request(url, "GET")
            .then((response) => {
                if (response.status === 0) {
                    let models = this.state.models;
                    let user = response.user;
                    if (typeof (user) === "object") {
                        for (const key in models) {
                            if (typeof (user[key]) !== "undefined") {
                                models[key] = user[key];
                            }
                        }
                        this.setState({
                            models: models,
                            payment_method: user['payment_method'] !== "" ? user['payment_method'] : null,
                            canRender: true
                        })
                    }
                }
            });
    }

    render() {
        const {inputs, payment_method, canSave, canRender} = this.state;

        if (!canRender) {

            return null;
        }

        const radio = <RadioColumns
            fields={inputs}
            name={"payment_method"}
            models={payment_method}
            handleChangeRadio={this.handleChangeRadio.bind(this)}
        />;

        let utilForm = null;

        if (payment_method === "RIB") {

            utilForm = <div className="row pt-4">
                <div className="col-md-12">
                    <label>Veuillez renseigner votre iban :</label>
                </div>
                {this.ibanForm()}
            </div>

        } else if (payment_method === "PAYPAL" || payment_method === "CLUB") {

            utilForm = <div className="row pt-4">
                <div className="col-md-12">
                    <label>Veuillez renseigner l'email associé à votre
                        compte {payment_method === "PAYPAL" ? "Paypal" : "Le Club Bio"} :</label>
                </div>
                {this.emailForm((payment_method === "PAYPAL" ? "id_paypal" : "email_club"))}
            </div>

        } else if (payment_method === "ASSOS") {

            utilForm = <div className="row pt-4">
                <div className="col-md-12">
                    <label>Choix de l'oeuvre de charité :</label>
                </div>
                <div className="col-md-12">
                    <div className={"form-group"}>
                        <select className="form-control" value={this.state.models.id_assos_partner}
                                onChange={this.validAssos.bind(this)} name="id_assos_partner"
                        >
                            <option value={""}>--</option>
                            <option value={"1"}>Union nationale de l'apiculture française</option>
                        </select>
                    </div>
                </div>
            </div>

        }

        const validation = (!!payment_method && (canSave || this.canShowButtonValidate(payment_method)) ?
            <div className="col-md-12">
                <div className="row">
                    <button className="btn btn-success" onClick={this.submitPayment.bind(this)}>Valider</button>
                </div>
            </div> : null);

        return (
            <div className="col-md-7">
                {radio}
                {utilForm}
                {validation}
            </div>
        )
    }
}

export default withRouter(Payment)
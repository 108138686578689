import React from 'react';
import logo from '../../assets/bio_promo_blanc.png';
import Loader from '../../assets/loader.gif';
import Pdf from '../../assets/CGU_BIOPROMO.pdf';

import Modal from 'react-modal';
import ReactHtmlParser from "react-html-parser";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        width: 'auto',
        height: 'auto',
        maxWidth: '1200px',
        overflow: 'hidden',
        overflowY: 'auto',
        right: 'auto',
        bottom: 'auto',
        padding: "10px",
        transform: 'translate(-50%, -50%)',
    }
};

const ModalStyle = {
    fontWeight:"bold",
    color: "rgba(0, 0, 0, 0.69)"
};

const absoluteButton = {
    position: "absolute",
    top: "10px",
    right: "10px"
};

const mentionStyle = {
    padding: "10px"
};

export default class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({modalIsOpen: true});
        document.body.classList.add("overflow-hidden");
    }

    afterOpenModal() {

    }

    closeModal() {
        this.setState({modalIsOpen: false});
        document.body.classList.remove("overflow-hidden");
    }

    render() {

        let modal = null;
        if (this.state.modalIsOpen) {
            let modalContent =
                <div className="row">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content mention_modal">
                            <br/>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                <span aria-hidden="true" style={absoluteButton}>&times;</span>
                            </button>
                            <p className="mentions" style={mentionStyle}>
                                <span id="title_metion-legale">MENTIONS LÉGALES</span> <br/><br/> <span
                                style={ModalStyle}>Editeur</span> Le présent site,
                                accessible à l'adresse www.biopromo.fr, est édité, hébergé et géré par : BIO A LA UNE,
                                SAS au capital de 20880 euros, immatriculée au RCS de Versailles sous le numéro B 510
                                517 451, dont le siège social est situé 14 RUE JEAN BONAL, 92250 LA GARENNE-COLOMBES,
                                FRANCE téléphone : 01 30 15 02 03<br/><br/>
                                <span style={ModalStyle}>Contenu</span> La structure
                                du site et tous les éléments le constituant, qu'ils soient protégés par des droits
                                d'auteur ou par les lois applicables, restent l'entière propriété de BIO A LA UNE. Toute
                                reproduction, extraction, affichage, altération, modification, adaptation ou utilisation
                                du Site ou de tout élément du Site, en tout ou partie, quelqu'en soient les moyens et
                                les objectifs, et plus généralement tout acte non expressément autorisé par ecrit, sont
                                strictement interdits et susceptibles de poursuites. Les marques, noms de marque, ou de
                                services, les logos et tout autre signe distinctif, qui sont affichés sur le Site sont
                                protégés par les lois Françaises, Européennes ou tout autre loi applicable. Toute
                                utilisation non autorisée ou reproduction est strictement interdite. BIO A LA UNE
                                apporte beaucoup d'attention à l'exactitude des données contenues sur son site web. Si
                                néanmoins vous trouvez une information incorrecte ou erronée dans nos pages, merci de
                                nous en avertir.<br/><br/>
                                <span
                                    style={ModalStyle}>Droit d'accés et rectification </span> Les
                                informations demandées sur le site sont destinées au bon fonctionnement du site.
                                Conformément à la loi n° 78-17 du 6 janvier 1978, toute personne dispose d’un droit
                                d’accès, de modification, de rectification et de suppression des données qui le
                                concernent en nous adressant un courrier et la photocopie d'une pièce d'identité à
                                l'adresse : BIO A LA UNE 14 Rue Jean Bonal, 92250 La Garenne-Colombes France<br/><br/>
                                <a target="_blank" href={Pdf}>Voir les CGU</a>
                            </p>
                        </div>
                    </div>
                </div>
            ;
            modal = <Modal
                key={"footer-modal"}
                isOpen={this.state.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={customStyles}
                //contentLabel={data.html}
            >
                {modalContent}
            </Modal>
        }
        return (
            [
                <div className={"col-md-12 footer"} key={"footer-one"}>
                    <div className={"row"}>
                        <div className={"col-md-6 text-left"}>
                            <img src={logo} alt={""}/>
                        </div>
                        <div className={"col-md-6 text-right"}>
                            <span className="text_footer">2013-2017 BIO A LA UNE</span>
                            <button onClick={this.openModal}>MENTIONS LEGALES</button>
                            <button>NOUS CONTACTER</button>
                        </div>
                    </div>
                </div>,
                <div key={"footer-two"} id={"call-loader"} className={"d-none"}>
                    <div id={"loader-element"}>
                        <img src={Loader} alt={"loader"}/>
                    </div>
                </div>,
                modal
            ]
        );
    }
}

import React from 'react'
import General from "./Account/General"
import Payment from "./Account/Payment"
import Password from "./Account/Password"
import {PageTitle} from "../../global/Others";
import {withRouter} from 'react-router-dom'
import ReactGA from "react-ga";

class Account extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            subtitles: [
                "Informations generales",
                "Informations de paiement",
                "Mot de passe"
            ]

        }
    }

    renderSelector() {
        let i = 0;
        let options = [];
        while (i < this.state.subtitles.length) {
            options = [...options,
                <option key={"options-account-selection-" + i} value={i + 1}>{this.state.subtitles[i]}</option>];
            i++;
        }
        return (
            <div className="col-md-12 pt-3">
                <div className={"col-md-3"}>
                    <div className={"form-group"}>
                        <select className="form-control" onChange={this.handleChangeSelector.bind(this)}
                                value={this.state.page}>
                            {options}
                        </select>
                    </div>
                </div>
            </div>
        );
    }

    handleChangeSelector(e) {
        this.setState({page: parseInt(e.target.value)});
    }

    componentDidMount() {

        ReactGA.initialize('UA-24145657-10');
        ReactGA.pageview(window.location.pathname + window.location.search);

        const {params} = this.props.match;
        let page = parseInt(params.page);
        if (!isNaN(page) && page <= this.state.subtitles.length) {
            this.setState({page: page});
        }
    }

    render() {
        let content = [];
        const page = this.state.page;
        if (page === 1) {
            content = [...content, <General key={"account-content"}/>]
        } else if (page === 2) {
            content = [...content, <Payment key={"account-content"}/>]
        } else if (page === 3) {
            content = [...content, <Password key={"account-content"}/>]
        }

        return (
            <div className="container pt-5">
                <PageTitle title={"Mon compte"}/>
                {this.renderSelector()}
                <div className="col-md-12">
                    {content}
                </div>
            </div>
        )
    }

}

export default withRouter(Account)
import React from 'react'
import {Link} from "react-router-dom";

export default class Card extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            baseUrl: "https://biopromo.fr",
        }
    }

    static makeImgUrl(id) {
        let path = '/_img/deals';
        id = id.toString();
        let charLength = id.length;
        let i = 0;
        while (i < 3) {
            let current = id.substring((charLength - 1), charLength);
            if (current === "") {
                current = "0";
            }
            path += '/' + current;
            charLength--;
            i++;
        }

        return path + "/" + id + ".jpg";
    }

    render() {
        let data = this.props.data;
        const{baseUrl} = this.state;
        let content = [];
        content = [...content, <div className="offre col-md-3" key={data.id_deal + "-card"}>
            <Link to={`/deal/${data.id_deal}`}>
                <img src={baseUrl + Card.makeImgUrl(data.id_deal)} className="img_pdt" alt={""}/>
            </Link>
            <div className="reduc_div">
                <p className="reduc">
                    <img className="picto" alt="" src={baseUrl + "/_img/cochon.png"}/>
                    <br/>
                    <span className="amount">- {parseFloat(data.amount_centime) / 100}€ </span>
                    <br/>
                    <span className="msg-deal">Remboursé</span>
                </p>
            </div>
            <div className={"marque_name"}>
                <p className="title_pdt">{data.name}</p>
            </div>
        </div>];

        return (content);
    }

};
